import React from 'react';

import Section from '../../../components/Section';
import Title from '../../../components/Title';
import Box from './Box';
import cypberCriminalsSrc from './cyber-criminals.svg';

const Enhance = () => (
  <Section id="Enhance">
    <div className="row text-center mt-5">
      <div className="col-12 mb-5">
        <Title>Enhance your cybersecurity</Title>
        <div className="mb-5" />
      </div>
    </div>

    <div className="row text-center mb-5">
      <div className="col-12 col-lg-3">
        <div className="d-flex flex-column h-100 justify-content-around">
          <Box title="Seamless UX">
            Authentication within the user session
          </Box>

          <Box title="Accurate">
            Deep learning based on unique movement patterns
          </Box>
        </div>
      </div>

      <div className="col-12 col-lg-6">
        <img
          src={cypberCriminalsSrc}
          alt="Cyber Criminals"
          className="img-fluid w-100"
        />
      </div>

      <div className="col-12 col-lg-3">
        <div className="d-flex flex-column h-100 justify-content-around">
          <Box title="Compliant">
            PSD2 and SCA compliance with no need of additional hardware
          </Box>

          <Box title="Cost-efficient">
            No SMS or paid for third party data required
          </Box>
        </div>
      </div>
    </div>
  </Section>
);

export default Enhance;
