import React from 'react';

import sentinelLogo from './logos/inverse-logo-graboxy-sentinel.svg';
import twofaLogo from './logos/inverse-logo-graboxy-2fa.svg';
import anticheatLogo from './logos/inverse-logo-graboxy-anticheat.svg';
import captchaLogo from './logos/inverse-logo-graboxy-captcha.svg';
import ciLogo from './logos/inverse-logo-cursor-insight.svg';
import styles from './HamburgerMenu.module.scss';

const HamburgerMenu = () => (
  <div className={styles.menuToggle}>
    <input type="checkbox" />
    <span />
    <span />
    <span />
    <ul className={styles.menu}>
      <h3>Graboxy Products</h3>
      <li>
        <a href="https://sentinel.graboxy.com/" target="_blank" rel="noreferrer">
          <img src={sentinelLogo} alt="Graboxy Sentinel logo" />
        </a>
      </li>
      <li>
        <a href="https://2fa.graboxy.com/" target="_blank" rel="noreferrer">
          <img src={twofaLogo} alt="Graboxy 2FA logo" />
        </a>
      </li>
      <li>
        <a href="https://anti-cheat.graboxy.com/#/" target="_blank" rel="noreferrer">
          <img src={anticheatLogo} alt="Graboxy Anti-Cheat logo" />
        </a>
      </li>
      <li>
        <a href="https://captcha.graboxy.com/#/" target="_blank" rel="noreferrer">
          <img src={captchaLogo} alt="Graboxy Captcha logo" />
        </a>
      </li>

      <h3 className="mt-4">Company Website</h3>
      <li>
        <a href="https://cursorinsight.com/" target="_blank" rel="noreferrer">
          <img src={ciLogo} alt="Cursor Insight logo" />
        </a>
      </li>
    </ul>
  </div>
);

export default HamburgerMenu;
