import { useEffect, useState } from 'react';

export default function useDocument(path) {
  const [document, setDocument] = useState('');

  useEffect(() => {
    fetch(path)
      .then((response) => response.text())
      .then((text) => setDocument(text));
  }, [path, setDocument]);

  return document;
}
