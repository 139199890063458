import React from 'react';

import Section from '../../../components/Section';
import RobotText from './RobotText';
import robotSrc from './images/robot.png';
import robotMobileSrc from './images/robot-mobile.png';
import styles from './Robot.module.scss';

const Robot = () => (
  <Section id="Robot" className={styles.Robot} fluid>
    <div className="d-none d-md-flex">
      <img
        src={robotSrc}
        alt="Robot"
        className="img-fluid w-100"
      />
    </div>

    <div className="d-flex d-md-none">
      <img
        src={robotMobileSrc}
        alt="Robot mobile"
        className="img-fluid w-100"
      />
    </div>

    <RobotText title="2500+" className={styles.RobotText1}>
      datapoints analized for each user
    </RobotText>

    <RobotText title="80%" className={styles.RobotText2}>
      of users favor Graboxy over one time codes
    </RobotText>

    <RobotText title="2M" className={styles.RobotText3}>
      biometric profiles created with the algorithm
    </RobotText>

    <RobotText title="5 seconds" className={styles.RobotText4}>
      or less is how long the authentication takes
    </RobotText>
  </Section>
);

export default Robot;
