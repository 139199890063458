import React from 'react';

import Document from '../../components/Document';
import PageLayout from '../../layouts/PageLayout';
import privacyPolicyPath from './privacy-policy.md';

const PrivacyPage = () => (
  <PageLayout>
    <Document path={privacyPolicyPath} />
  </PageLayout>
);

export default PrivacyPage;
