import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Seo = ({ title, description, url }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />

    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content="" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="graboxy.com" />
    <meta property="twitter:url" content={url} />
    <meta name="twitter:site" content={url} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content="" />
  </Helmet>
);

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
};

Seo.defaultProps = {
  title: 'Graboxy 2FA',
  description: 'The most convenient biometric 2FA service',
  url: window.location.href,
};

export default Seo;
