import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-scroll';

import HamburgerMenu from '../HamburgerMenu';
import Logo from '../Logo';
import sentinelLogo from './logos/inverse-logo-graboxy-sentinel.svg';
import twofaLogo from './logos/inverse-logo-graboxy-2fa.svg';
import anticheatLogo from './logos/inverse-logo-graboxy-anticheat.svg';
import captchaLogo from './logos/inverse-logo-graboxy-captcha.svg';
import ciLogo from './logos/inverse-logo-cursor-insight.svg';
import styles from './Navbar.module.scss';

const className = [
  'navbar fixed-top navbar-expand-lg navbar-dark',
  styles.Navbar,
].join(' ');

const productMenu = [
  'navbar-nav d-block d-lg-none',
  styles.ProductMenu,
].join(' ');

const Navbar = () => {
  const { pathname } = useLocation();

  const hidden = pathname.replace('/', '');

  return (
    <nav className={className}>
      <HamburgerMenu />
      <div className="container">
        <Link
          to="root"
          className="navbar-brand"
          style={{ cursor: 'pointer' }}
          spy
          smooth
        >
          <Logo />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mt-3 mb-4 my-lg-0 ms-lg-5">
            <li className="nav-item">
              <a
                href="https://graboxy.com/blog/1?search=2fa"
                target="_blank"
                rel="noreferrer"
                className={styles.BlogButton}
              >
                Blog
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto mt-3 mb-4 my-lg-0">
            <li className="nav-item" hidden={hidden}>
              <Link
                to="Contact"
                className={styles.ContactButton}
                spy
                smooth
              >
                Contact
              </Link>
            </li>
          </ul>
          <ul className={productMenu}>
            <h3>Graboxy Products</h3>
            <li className="nav-item mb-3">
              <a
                href="https://sentinel.graboxy.com/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={sentinelLogo} alt="Graboxy Sentinel logo" />
              </a>
            </li>
            <li className="nav-item mb-3">
              <a
                href="https://2fa.graboxy.com/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={twofaLogo} alt="Graboxy 2FA logo" />
              </a>
            </li>
            <li className="nav-item mb-3">
              <a
                href="https://anti-cheat.graboxy.com/#/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={anticheatLogo} alt="Graboxy Anti-Cheat logo" />
              </a>
            </li>
            <li className="nav-item mb-3">
              <a
                href="https://captcha.graboxy.com/#/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={captchaLogo} alt="Graboxy Captcha logo" />
              </a>
            </li>

            <h3 className="mt-4">Company Website</h3>
            <li className="nav-item mb-3">
              <a
                href="https://cursorinsight.com/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={ciLogo} alt="Cursor Insight logo" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
