import React from 'react';
import PropTypes from 'prop-types';

const Quote = ({
  image, name, description, quote,
}) => (
  <div className="card border-0">
    <div className="card__image">
      <img src={image} alt={`Profile of ${name}`} />
    </div>

    <div className="card__text">
      <h5 className="card__text--name">
        {name}
      </h5>
      <h6 className="card__text--description">
        {description}
      </h6>
      <span className="card__text--icon" />
      <p className="card__text--quote-style">
        {quote}
      </p>
    </div>
  </div>
);

Quote.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
};

export default Quote;
