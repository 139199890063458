import imgMoritzGrumbach from './images/quote-moritz-grumbach.jpg';
import imgAdamFeldmann from './images/quote-adam-feldmann.jpg';
import imgFaridSingh from './images/quote-farid-singh.jpg';

const QUOTES = [
  {
    image: imgMoritzGrumbach,
    name: 'Moritz Grumback',
    description: 'Founder & Managing Director, Vertum.Group',
    quote: [
      'I had the pleasure of getting to know Cursor Insight and their product',
      'Graboxy in the course of the Cybernorth Accelerator. Not only has',
      'Graboxy been one of the very highlights of the program since its start,',
      'but also one of the few genius – and yet performing! – ideas that one',
      'wants to accompany, foster and boost just for its proper excellence.',
    ].join(' '),
  },
  {
    image: imgAdamFeldmann,
    name: 'Adam Feldmann PhD',
    description: 'Head of Big Data Research Group, University of Pecs',
    quote: [
      'The professional and approachable team behind Graboxy truly understands',
      'the scientific demands of cutting edge technology research. They',
      'designed and carried out high quality research projects and are clearly',
      'capable of commercializing the outcomes.',
    ].join(' '),
  },
  {
    image: imgFaridSingh,
    name: 'Farid Singh',
    description: 'CEO, Take 3 Innovate',
    quote: [
      'Graboxy is changing how we think of digital sign-on and safety. Think',
      'simple, seamless and single device login, verification and anti fraud,',
      'in one service.',
    ].join(' '),
  },
];

export default QUOTES;
