import React from 'react';
import { Link } from 'react-router-dom';
import ReactCookieConsent from 'react-cookie-consent';

import styles from './CookieConsent.module.scss';

const CookieConsent = () => (
  <ReactCookieConsent
    location="bottom"
    buttonText="Consent"
    cookieName="Graboxy Captcha Landing"
    containerClasses={styles.CookieConsent}
    style={{ background: '#442458' }}
    buttonStyle={{
      background: 'linear-gradient(301.46deg, #EF3355 30.86%, #C766F4 73.16%)',
      border: '0',
      borderRadius: '999px',
      boxShadow: 'none',
      color: '#fff',
      cursor: 'pointer',
      flex: '0 0 auto',
      padding: '10px 25px',
      margin: '15px',
    }}
    expires={150}
  >
    This website uses
    {' '}
    <Link to="/cookie">
      cookies
    </Link>
    {' '}
    to enhance the user experience.
  </ReactCookieConsent>
);

export default CookieConsent;
