import React from 'react';

import Section from '../../../components/Section';
import Title from '../../../components/Title';
import awardsFullSrc from './images/awards-full.png';
import awards1Src from './images/awards1.png';
import awards2Src from './images/awards2.png';

const Awards = () => (
  <Section id="Awards" fluid>
    <div className="row text-center">
      <div className="col-12 mb-5">
        <Title>Awards</Title>
      </div>

      <div className="col-12 d-none d-md-block">
        <img
          src={awardsFullSrc}
          alt="Awards illustrations"
          className="img-fluid"
        />
      </div>

      <div className="col-12 d-block d-md-none">
        <img
          src={awards1Src}
          alt="Awards illustration 1"
          className="img-fluid"
        />
      </div>

      <div className="col-12 d-block d-md-none">
        <img
          src={awards2Src}
          alt="Awards illustration 2"
          className="img-fluid"
        />
      </div>
    </div>
  </Section>
);

export default Awards;
