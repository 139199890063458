import React from 'react';

import Section from '../../../components/Section';
import Title from '../../../components/Title';
import Laptop from './Laptop';
import styles from './UseCases.module.scss';

const UseCases = () => (
  <Section id="UseCases" className={styles.UseCases} triangleColor="#ffffff">
    <div className="row text-center mt-5">
      <div className="col-12">
        <Title inverse>
          Authenticate your users
          <span>just by a short mouse or finger movement</span>
        </Title>
      </div>

      <div className="col-12 my-5">
        <h3>All in 5 seconds. Without breaking the user flow.</h3>
      </div>

      <div className="col-12 mt-3">
        <Laptop />
      </div>
    </div>
  </Section>
);

export default UseCases;
