import React from 'react';
import PropTypes from 'prop-types';

import Triangle from './Triangle';

const Section = ({
  id, className, triangleColor, fluid, children,
}) => {
  const classes = ['py-5', className].join(' ');

  return (
    <section id={id} className={classes} style={{ position: 'relative' }}>
      <div className={fluid ? 'container-fluid' : 'container'}>
        {children}
      </div>

      {triangleColor && <Triangle color={triangleColor} />}
    </section>
  );
};

Section.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  triangleColor: PropTypes.string,
  fluid: PropTypes.bool,
  children: PropTypes.node,
};

Section.defaultProps = {
  className: undefined,
  triangleColor: undefined,
  fluid: false,
  children: undefined,
};

export default Section;
