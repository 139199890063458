import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import useDocument from './useDocument';
import styles from './Document.module.scss';

const Document = ({ path }) => {
  const document = useDocument(path);

  return (
    <section>
      <div className="container mb-5">
        <div className="row mt-5">
          <div className="col-12">
            <ReactMarkdown
              components={{ h1: 'h2' }}
              className={styles.Document}
            >
              {document}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </section>
  );
};

Document.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Document;
