import React from 'react';
import PropTypes from 'prop-types';

import styles from './Box.module.scss';

const Box = ({ title, children }) => (
  <div className={styles.Box}>
    <h3>{title}</h3>
    <p>{children}</p>
  </div>
);

Box.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default Box;
