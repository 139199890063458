import React from 'react';
import PropTypes from 'prop-types';

import styles from './Triangle.module.scss';

const Triangle = ({ color }) => (
  <div
    className={styles.Triangle}
    style={{ borderTop: `25px solid ${color}` }}
  />
);

Triangle.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Triangle;
