import React from 'react';

import Section from '../../../components/Section';
import Title from '../../../components/Title';
import Step from './Step';
import step1ImgSrc from './images/step-1-record.png';
import step2ImgSrc from './images/step-2-identify.png';
import step3ImgSrc from './images/step-3-biometric-profile.png';
import styles from './Steps.module.scss';

const Steps = () => (
  <Section id="Steps" className={styles.Steps}>
    <div className="row text-center">
      <div className="col-12 mb-5">
        <Title inverse>
          Onboard users
          <span>in less than 2 minutes</span>
        </Title>
      </div>
    </div>

    <Step idx={1} image={step1ImgSrc}>
      Record desktop or mobile movement
    </Step>

    <Step idx={2} image={step2ImgSrc} inverse>
      Identifying unique movement features
    </Step>

    <Step idx={3} image={step3ImgSrc}>
      Creating individual biometric profile
    </Step>

    <div className="row text-center">
      <div className="col-12 mt-3">
        <h3>That&apos;s all</h3>
      </div>
    </div>
  </Section>
);

export default Steps;
