import React from 'react';
import PropTypes from 'prop-types';

import styles from './IconText.module.scss';

const iconTextClasses = [
  'col-6 col-xl-3',
  styles.IconText,
].join(' ');

const IconText = ({ imgSrc, description }) => (
  <div className={iconTextClasses}>
    <div className="mt-4">
      <img src={imgSrc} alt={description} className="img-fluid" />
      <h3 className="mt-2">{description}</h3>
      <span />
    </div>
  </div>
);

IconText.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  description: PropTypes.node,
};

IconText.defaultProps = {
  description: undefined,
};

export default IconText;
