import React from 'react';
import { Link } from 'react-scroll';

import Section from '../../../components/Section';
import IconText from './IconText';
import bankIcon from './images/hero-bank-icon.svg';
import creditCardIcon from './images/hero-credit-card-icon.svg';
import controllerIcon from './images/hero-controller-icon.svg';
import enterpriseIcon from './images/hero-enterprise-icon.svg';
import styles from './Hero.module.scss';

const Hero = () => (
  <Section id="Hero" className={styles.Hero}>
    <div className="row text-center">
      <div className="col-12 mt-5 pt-5">
        <h1>
          The most convenient
          <br/>
          biometric 2FA service
        </h1>
      </div>

      <div className="col-12 mt-5">
        <span className={styles.HeroButton}>
          <Link to="Contact" smooth>Request a Demo</Link>
        </span>
      </div>

      <div className="col-12 col-lg-10 my-5 mx-auto">
        <h3>
          Fight identity theft and account takeover fraud with motion-based
          authentication.
          <br />
          Authenticate users with a simple movement challenge in just 5
          seconds. No need to switch between devices and apps.
        </h3>
      </div>
    </div>

    <div className="row text-center mb-5 mx-auto" style={{ maxWidth: 1080 }}>
      <IconText imgSrc={bankIcon} description="Online banks" />
      <IconText imgSrc={creditCardIcon} description="Payment services" />
      <IconText imgSrc={controllerIcon} description="Gaming platforms" />

      <IconText
        imgSrc={enterpriseIcon}
        description="SaaS & enterprise accounts"
      />
    </div>
  </Section>
);

export default Hero;
