import React from 'react';
import PropTypes from 'prop-types';

import checkSrc from './images/check.svg';

const ListItem = ({ children }) => (
  <li
    className="d-flex flex-row align-items-center my-4"
    style={{ maxWidth: 360 }}
  >
    <img
      src={checkSrc}
      width={32}
      height={23}
      alt="Check"
    />

    <div className="ms-4">
      {children}
    </div>
  </li>
);

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListItem;
