import React from 'react';
import { Link } from 'react-scroll';

import Section from '../../../components/Section';
import Title from '../../../components/Title';
import ListItem from './ListItem';
import dashboardSrc from './images/dashboard.png';
import styles from './Dashboard.module.scss';

const listClasses = [
  styles.List,
  'mx-auto mx-lg-0',
].join(' ');

const Dashboard = () => (
  <Section id="Dashboard" triangleColor="#2e153d">
    <div className="row">
      <div className="col-12 mb-4">
        <Title>
          Quick setup and user metrics
          <span>at your fingertips</span>
        </Title>
      </div>
    </div>

    <div className="row align-items-center">
      <div className="col-12 col-lg-7 text-center text-lg-end">
        <img
          src={dashboardSrc}
          alt="Dashboard illustration"
          className="img-fluid"
        />
      </div>

      <div className="col-12 col-lg-5">
        <ul className={listClasses}>
          <ListItem>Software-as-a-Service</ListItem>
          <ListItem>Seamless integration to fraud detection tools</ListItem>
          <ListItem>0Auth2 and other industry-standard integrations</ListItem>
          <ListItem>Real-time activity reporting and monitoring</ListItem>
        </ul>
      </div>

      <div className="col-12 text-center my-4">
        <Link to="Contact" className={styles.Button} smooth>
          Request a Demo
        </Link>
      </div>
    </div>
  </Section>
);

export default Dashboard;
