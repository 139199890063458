import React from 'react';
import PropTypes from 'prop-types';

import styles from './Title.module.scss';

const Title = ({ inverse, children }) => (
  <h2 className={inverse ? styles.InverseTitle : styles.Title}>
    {children}
  </h2>
);

Title.propTypes = {
  inverse: PropTypes.bool,
  children: PropTypes.node,
};

Title.defaultProps = {
  inverse: false,
  children: undefined,
};

export default Title;
