import React from 'react';
import PropTypes from 'prop-types';

import Navbar from '../components/Navbar';
import BaseLayout from './BaseLayout';

const PageLayout = ({ children }) => (
  <BaseLayout>
    <Navbar />
    <main>{children}</main>
  </BaseLayout>
);

PageLayout.propTypes = {
  children: PropTypes.node,
};

PageLayout.defaultProps = {
  children: undefined,
};

export default PageLayout;
