import React from 'react';
import PropTypes from 'prop-types';

import logoPath from './graboxy-2fa-logo.svg';

const Logo = ({ className }) => (
  <img
    src={logoPath}
    alt="Graboxy 2FA Logo"
    className={className}
    style={{ width: '204px' }}
  />
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: undefined,
};

export default Logo;
