import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './Question.module.scss';

const Question = ({ question, children }) => {
  const [hidden, setHidden] = useState(true);

  const onClick = useCallback(() => {
    setHidden((h) => !h);
  }, [setHidden]);

  return (
    <div className={styles.Question} onClick={onClick} role="none">
      <div className="d-flex flex-row">
        {hidden
          ? <i className="fa fa-plus" />
          : <i className="fa fa-minus" />}

        <h3>{question}</h3>
      </div>

      <p className="mt-3" hidden={hidden}>
        {children}
      </p>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Question;
