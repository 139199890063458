import React from 'react';

import PageLayout from '../../layouts/PageLayout';
import Hero from './Hero';
import Enhance from './Enhance';
import UseCases from './UseCases';
import Steps from './Steps';
import Robot from './Robot';
import Dashboard from './Dashboard';
import Awards from './Awards';
import Quotes from './Quotes';
import Faq from './Faq';
import Contact from './Contact';
import styles from './IndexPage.module.scss';

const IndexPage = () => (
  <PageLayout>
    <Hero />
    <Enhance />
    <div className={styles.Background}>
      <UseCases />
      <Steps />
      <Robot />
    </div>
    <Dashboard />
    <Awards />
    <Quotes />
    <Faq />
    <Contact />
  </PageLayout>
);

export default IndexPage;
