import React, {
  useEffect, useRef, useState,
} from 'react';

import laptopSrc from './laptop.svg';
import styles from './Laptop.module.scss';

const AR = 315 / 560;

const Laptop = () => {
  const imgRef = useRef();
  const [style, setStyle] = useState({});

  useEffect(() => {
    window.onresize = () => {
      if (imgRef.current) {
        let { width } = imgRef.current;
        width *= 0.74;

        const left = Math.round(width * 0.184);
        const top = Math.round(width * 0.052);

        setStyle({
          left,
          top,
          height: Math.ceil(width * AR),
          width: Math.round(width),
        });
      }
    };

    window.onresize();

    return () => {
      window.onresize = undefined;
    };
  }, [imgRef, setStyle]);

  return (
    <div className={styles.Laptop}>
      <img
        ref={imgRef}
        src={laptopSrc}
        alt="Laptop"
        className="img-fluid w-100"
      />

      <iframe
        src="https://player.vimeo.com/video/703246691?h=eb520adbae"
        className="img-fluid"
        style={style}
      >
      </iframe>
    </div>
  );
};

export default Laptop;
