import React from 'react';

import Document from '../../components/Document';
import PageLayout from '../../layouts/PageLayout';
import termsAndConditionsPath from './terms-and-conditions.md';

const TermsPage = () => (
  <PageLayout>
    <Document path={termsAndConditionsPath} />
  </PageLayout>
);

export default TermsPage;
