import React from 'react';

import Section from '../../../components/Section';
import Title from '../../../components/Title';
import Question from './Question';
import QUESTIONS from './Faq.data';

const Faq = () => {
  const renderedQuestions = QUESTIONS.map((question) => (
    <Question key={question.id} {...question} />
  ));

  return (
    <Section id="Faq">
      <div className="col-12 mb-5">
        <Title>FAQ</Title>
      </div>

      <div className="col-12 mx-auto mb-5" style={{ maxWidth: 992 }}>
        {renderedQuestions}
      </div>
    </Section>
  );
};

export default Faq;
