import React from 'react';
import PropType from 'prop-types';

import Title from '../../../components/Title';
import styles from './RobotText.module.scss';

const RobotText = ({ title, className, children }) => {
  const classes = [styles.RobotText, className].join(' ');

  return (
    <div className={classes}>
      <Title>
        <span>{title}</span>
      </Title>

      <p>{children}</p>
    </div>
  );
};

RobotText.propTypes = {
  title: PropType.string.isRequired,
  className: PropType.string.isRequired,
  children: PropType.string.isRequired,
};

export default RobotText;
