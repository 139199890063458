import React from 'react';

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import IndexPage from './pages/IndexPage';
import CookiePage from './pages/CookiePage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import CookieConsent from './components/CookieConsent';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={IndexPage} />
      <Route exact path="/cookie" component={CookiePage} />
      <Route exact path="/privacy" component={PrivacyPage} />
      <Route exact path="/terms" component={TermsPage} />
      <Redirect path="**" to="/" />
    </Switch>
    <CookieConsent />
  </BrowserRouter>
);

export default App;
