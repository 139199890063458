import React from 'react';

import Document from '../../components/Document';
import PageLayout from '../../layouts/PageLayout';
import cookiePolicyPath from './cookie-policy.md';

const CookiePage = () => (
  <PageLayout>
    <Document path={cookiePolicyPath} />
  </PageLayout>
);

export default CookiePage;
