import React from 'react';
import { Link } from 'react-router-dom';

import {
  Form,
  CheckBox,
  TextInput,
  SubmitButton,
} from 'custom-form-client/react';

import Section from '../../../components/Section';
import Title from '../../../components/Title';
import styles from './Contact.module.scss';

import {
  CAPTCHA_URL_BASE,
  GRABOXY_API_KEY,
  TRAP_SERVER_URL,
} from '../../../constants';

const Contact = () => (
  <Section
    id="Contact"
    className={styles.Contact}
    triangleColor="#ffffff"
    fluid
  >
    <div className="row text-center">
      <div className="col-12 my-4">
        <Title inverse>
          Want to know
          <span>more about Graboxy?</span>
        </Title>
      </div>
    </div>

    <div className="row mb-5">
      <div className="col-12 mx-auto" style={{ maxWidth: 640 }}>
        <Form
          id="form"
          type="contact"
          action={process.env.REACT_APP_CUSTOM_FORM_URL}
          responseMessage="Thank you for your message."
          captchaType="graboxyCaptcha"
          captchaSiteKey={process.env.REACT_APP_GRABOXY_CAPTCHA_SITE_KEY}
          captchaOptions={{
            urlBase: CAPTCHA_URL_BASE,
            apiKey: GRABOXY_API_KEY,
            trapUrl: TRAP_SERVER_URL,
          }}
          className={styles.Form}
        >
          <TextInput id="name" label="Name" className="mb-3" required />

          <TextInput
            id="email"
            label="E-mail"
            type="email"
            className="mb-3"
            required
          />

          <TextInput id="company" label="Company" className="mb-3" />
          <TextInput id="message" label="Message" className="mb-4" multiline />

          <CheckBox id="subscribe" className="mb-3">
            Send me news about product updates
          </CheckBox>

          <CheckBox id="accept" className="mb-3" required>
            I consent to the use of my personal information as specified in the
            {' '}
            <Link to="/privacy" target="_blank" className="text-nowrap">
              Privacy Policy
            </Link>
            , including the
            {' '}
            <Link to="/cookie" target="_blank" className="text-nowrap">
              Cookie Policy
            </Link>
            .
            <small>(Required)</small>
          </CheckBox>

          <SubmitButton className={styles.SubmitButton}>
            Send
          </SubmitButton>
        </Form>
      </div>
    </div>
  </Section>
);

export default Contact;
