const QUESTIONS = [
  {
    question: 'Can you really tell if it’s me by solving a maze?',
    children: [
      'The algorithm is developed by Cursor Insight, a software development',
      'company with 15+ years of experience in motion analysis. Graboxy',
      'calculates more than 2500 unique features for each individual',
      'movement sample.',
    ].join(' '),
  },
  {
    question: 'What does it take to learn my movement?',
    children: [
      'To use Graboxy, you need to repeat the movement a few times, that’s',
      'how our algorithm creates your profile.',
    ].join(' '),
  },
  {
    question: [
      'What if somebody secretly watches how I move and repeats',
      'my movement?',
    ].join(' '),
    children: [
      'Even if somebody watches your movement, your fine-motor movement',
      'patterns are extremely difficult to mimic. Movement biometrics',
      'provide the same level of security as fingerprints.',
    ].join(' '),
  },
  {
    question: 'What if somebody steals my movement sample?',
    children: [
      'If somebody secretly records the cursor movement, the exact same',
      'replay of the movement sample cannot spoof the algorithm - just as',
      'you never sign your name exactly the same way twice, your cannot',
      'exactly repeat your movement sample again. Our algorithm will',
      'recognise that it’s a replay.',
    ].join(' '),
  },
  {
    question: [
      'I don’t like being observed. Now you are recording my',
      'mouse movements as well?',
    ].join(' '),
    children: [
      'It’s important to know that we only collect the movements for',
      'authentication purposes and never share them with a third party.',
      'They are only being recorded while solving the on-screen challenge.',
    ].join(' '),
  },
  {
    question: [
      'I sometimes use the touchpad and sometimes I connect an external',
      'mouse. Will both work?',
    ].join(' '),
    children: [
      'Yes, they will. You do need to register your movement sample with both',
      'devices though. It is an initial small investment of time that will',
      'bring return later by the time saved not looking for your phone,',
      'opening verification apps or copy-pasting one-time codes.',
    ].join(' '),
  },
].map((question, i) => ({ id: `q${i}`, ...question }));

export default QUESTIONS;
