import { Link } from 'react-router-dom';

import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.Footer}>
    <div className="container py-3">
      <div className="row align-items-center">
        <div className="col-lg-6 text-center text-lg-start">
          <Link to="/cookie" target="_blank">
            Cookie Policy
          </Link>

          <Link to="/privacy" target="_blank" className="mx-3">
            Privacy Policy
          </Link>

          <Link to="/terms" target="_blank">
            Terms & Conditions
          </Link>
        </div>

        <div className="col-lg-6 text-center text-lg-end">
          <span>
            © 2022,
            <a
              href="https://cursorinsight.com"
              target="_blank"
              rel="noreferrer"
              className="ms-2"
            >
              Cursor Insight Ltd.
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
