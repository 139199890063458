import React from 'react';
import Slider from 'react-slick';

import Section from '../../../components/Section';
import Quote from './Quote';
import QUOTES from './Quotes.data';
import './Quotes.scss';

const SETTINGS = {
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  responsive: [
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Quotes = () => {
  const renderedQuotes = QUOTES.map((quote) => (
    <Quote key={quote.name} {...quote} />
  ));

  return (
    <Section id="Quotes">
      <div className="row">
        <div className="col-12">
          <Slider {...SETTINGS}>
            {renderedQuotes}
          </Slider>
        </div>
      </div>
    </Section>
  );
};

export default Quotes;
